.contact-img-div{
    border: 1px solid rgba(0, 0, 0, 0);
    
    width: 100%;
    height: 430px;
    margin-bottom: 50px;
    
}
.contactimg{
    width: 100%;
    height: 430px;
    position: absolute;
    filter: brightness(80%);
   

}


.contact-banner {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 400px;
  background-image: url(./images/c2.jpg);
  background-size: cover;
  /* background-attachment: fixed;
background-position-y: 215%;
background-position-x: 30%; */
filter: brightness(95%);
}

.contact-banar-name{
  position: absolute;
  color: rgb(246, 243, 243);
  font-family:'Times New Roman', Times, serif;
  text-shadow:
  1px 1px 2px black,
  0 0 0.1em rgb(0, 0, 0),
  0 0 0.2em rgb(0, 0, 0);
  font-weight: 600;
  font-size: 70px;

}



.contact-container-div{
    display: flex;
}
.contact-info-left{
    /* border: 1px solid rgb(255, 0, 0); */
    height: 800px;
    width: 60%;
    position: relative;
}

.contact-info-right{
    position: relative;
    /* border: 1px solid black; */
    height: 800;
    background-color: rgb(0, 2, 46);

    width: 40%;
}
.contact-info-center-box{
    position: absolute;
    /* border: 1px solid black; */
    height: 700px;
    width: 40%;
    margin-top: 50px;
    margin-left: 50%;
    /* background-color: rgb(115, 8, 222); */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    z-index: 9;
}

/* ///////////////////// */

.consultation-container {
  max-width: 600px;
  margin:10%;
  /* padding-top: 20px; */
  border-radius: 5px;

}

.consultation-heading{
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  font-size: 50px;
}

.consultation-para{
  color: #6b6a6a;
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;

}

@media (min-width: 768px) and (max-width: 1024px) {
  .contact-img-div{
    border: 1px solid rgba(0, 0, 0, 0);
    
    width: 100%;
    height: 430px;
    margin-bottom: 50px;
    
}
.contactimg{
    width: 100%;
    height: 430px;
    position: absolute;
    filter: brightness(80%);
   

}


.contact-banner {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center; 
  width: 100%;
  height: 400px;
  background-image: url(./images/c2.jpg);
  background-size: cover;
  /* background-attachment: fixed;
background-position-y: 215%;
background-position-x: 30%; */
filter: brightness(95%);
}

.contact-banar-name{
  position: absolute;
  color: rgb(246, 243, 243);
  font-family:'Times New Roman', Times, serif;
  text-shadow:
  1px 1px 2px black,
  0 0 0.1em rgb(0, 0, 0),
  0 0 0.2em rgb(0, 0, 0);
  font-weight: 600;
  font-size: 70px;

}



.contact-container-div{
    display: flex;
}
.contact-info-left{
    /* border: 1px solid rgb(255, 0, 0); */
    height: 800px;
    width: 60%;
    position: relative;
}

.contact-info-right{
    position: relative;
    /* border: 1px solid black; */
    height: 800;
    background-color: rgb(0, 2, 46);

    width: 40%;
}
.contact-info-center-box{
    position: absolute;
    /* border: 1px solid black; */
    height: 700px;
    width: 40%;
    margin-top: 50px;
    margin-left: 56%;
    /* background-color: rgb(115, 8, 222); */
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    z-index: 9;
}

/* ///////////////////// */

.consultation-container {
  max-width: 600px;
  margin:10%;
  /* padding-top: 20px; */
  border-radius: 5px;

}

.consultation-heading{
  text-align: center;
  color: #000000;
  margin-bottom: 30px;
  font-size: 40px;
}

.consultation-para{
  color: #6b6a6a;
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;

} 
}

/* Media query for screens 720px and below */
@media (min-width: 481px) and (max-width: 768px){
  
.contact-container-div {
  display: flex;
  justify-content: space-between;
  margin: 20px;
}

.contact-info-left {
  height: auto;
  width: 60%;
  padding: 20px;

}

.contact-info-center-box {
  width: 35%;
  margin-top: 20px;
  padding: 20px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  position: relative;
}

.contact-info-right {
  width: 40%;
  background-color: rgb(0, 2, 46);
}
  .contact-container-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
  }
  .consultation-heading {
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
    font-size: 37px;
}
.consultation-para {
  color: #6b6a6a;
  line-height: 1.8;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 400;
}
.contact-form {
  padding-left: 116px !important;
}
  .contact-info-left,
  .contact-info-center-box,
  .contact-info-right {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }

  .contact-info-center-box {
    margin-top: 0;
    box-shadow: none;
  }
}

@media (max-width: 426px) {
  
  .contact-container-div {
    display: flex;
    justify-content: space-between;
    margin: 20px;
  }
  
  .contact-info-left {
    height: auto;
    width: 60%;
    padding: 20px;
  
  }
  
  .contact-info-center-box {
    width: 35%;
    margin-top: 20px;
    padding: 20px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .contact-info-right {
    width: 40%;
    background-color: rgb(0, 2, 46);
  }
    .contact-container-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 10px;
    }
    .consultation-heading {
      text-align: center;
      color: #000000;
      margin-bottom: 30px;
      font-size: 27px;
  }
  .consultation-para {
    color: #6b6a6a;
    line-height: 1.8;
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 400;
  }
  
    .contact-info-left,
    .contact-info-center-box,
    .contact-info-right {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
    }
  
    .contact-info-center-box {
      margin-top: 0;
      box-shadow: none;
    }
  }
  


.header-nav {
    /* border: 1px solid rgb(146, 130, 130); */
    position: sticky;
    background-color: rgb(249, 246, 246);
    width: 100%;
    height: 80px;
    top: 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;

}
a{
    text-decoration: none !important;
    color: black;
}
.REWA-logo{
height: 60px;
margin-top: 3%;
margin-left: 65%;
   

}

/* .li:hover{
  background-color: rgb(159, 2, 2);
    color: #fff;
} */

.li1{
    
    border: none;
    width: 110px;
    /* padding-left: 20px; */
   
}
.li2{
    width: 190px;
   
}

.header-links-nav {
    display: flex;
    /* margin-right: 100px; */
    margin-top: 15px;
}

.header-links-nav ul li {
    display: inline-block;

}

.header-links-nav ul li a {
    border: 1px black solid;
    margin: 15px;
    border-radius: 12px;
    padding: 5%;
    margin: -3%;
    border: none;
    text-decoration: none;
    color: rgb(0, 0, 0);
    /* padding-right: 0px; */
    font-weight: 620;
    font-size:large;

}

.logo-div a{
    border: none !important;
    
}




/* .header-links-nav ul li a:hover .icon{
    background-color: maroon;
    color: rgb(255, 255, 255)
} */
.header-links-nav ul li a:hover{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255)
}
.study-dest:hover{
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255)
    
}


.header-links-nav ul button {
    background-color: rgb(67, 66, 66);
    border-radius: 7px;
    border: none;
    color: #ffffff;
    display: inline-block;
    margin-right: 30px;
    font-weight: 700;
    line-height: 1;
    padding: 12px;
    position: relative;
    text-transform: capitalize;
    width: 110px;
    margin-top: -10px;
    z-index: 1;
    font-size:medium;

}

.study-dest{
    border: 1px black solid;
    margin: 15px;
    border-radius: 12px;
    padding: 5%;
    margin: -3%;
    border: none;
    text-decoration: none;
    color: rgb(0, 0, 0);
    /* padding-right: 0px; */
    font-weight: 620;
    font-size: large;
    cursor: pointer;
}

.dropdown-nav {
    position: relative;
    display: block;

}



.dropdown-content-nav {

    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 230px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 99;
    margin-left: -70px;
    margin-top: 7px;
    /* padding-top: 15px; */
    text-align: center;
    border-radius: 15px;
}

.dropdown-content1-nav {

    display: none;
    position: absolute;
    background-color: #ffffff;
    width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -20px;
    margin-top: 10px;
    border-radius: 15px;
    /* padding-top: 15px; */
    text-align: center;
    
}

.dropdown-nav:hover .dropdown-content-nav {
    display: block;
}

.dropdown-nav:hover .dropdown-content1-nav {
    display: block;
}

.dropdown-content1-nav,
.dropdown-content-nav,
p {
    font-size: 15px;
    font-weight: 700;

}


.innerdiv:hover{
    border-radius: 15px;
    background-color: rgb(0, 0, 0);
    width: 100%;
    color: rgb(255, 255, 255);
 
}
.innerdiv:hover a{
    border-radius: 15px;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255) !important;
}


.dropdown-content1-nav .innerdiv {
    width: 100%;
    padding: 1px 0px 1px 0px;
    text-align: center;


}
.dropdown-content1-nav .innerdiv a{
    width: 100%;
    padding: 1px 0px 1px 0px;
    text-align: center;
    text-decoration: none !important;
    color: black;

}


.dropdown-content-nav .innerdiv {
    width: 100%;
    padding: 1px 0px 1px 0px;
    text-align: center;

}

.header-links-nav ul li a:hover .icon {
    transform: rotate(180deg);
    transition: .5s ease;
}

.icon {
    position: absolute;
    margin-top: 5px;
}



/* ////////////////////////////////////////////////////// */


label #btn,
label #cancel {
    line-height: 75px;
    font-size: 31px;
    cursor: pointer;
    display: none;
    float: right;
    margin-right: 60px;

    color: rgb(0, 2, 7);
}


#check {
    display: none;
}

@media (min-width:769px) {
    #check:checked~label #cancel {
        display: none !important;
    }

}

@media (max-width:768px) {
    label #btn {

        display: block;

    }

    .header-links-nav {
        position: fixed;
        top: 62px;
        width: 100%;
        display: block !important;
        text-align: center;
        background-color: rgb(170, 233, 234);
        left: -150%;
        transition: all .5s linear;

    }
    .dropdown-content-nav , .dropdown-content1-nav{
        position: relative;
      margin: auto;
       
    }
    
}
@media (max-width:450px) {
    label #btn {

        display: block;

    }
    .REWA-logo {
        height: 46px;
        margin-top: 3%;
        margin-left: 20%;
    }
    .header-links-nav {
        position: fixed;
        top: 62px;
        width: 100%;
        display: block !important;
        text-align: center;
        background-color: rgb(170, 233, 234);
        left: -150%;
        transition: all .5s linear;

    }
    .dropdown-content-nav , .dropdown-content1-nav{
        position: relative;
      margin: auto;
       
    }
    .header-links-nav ul button {
        margin-left: 35px;
    }
    
}
@media (max-width:320px) {
    label #btn {

        display: block;

    }
    .REWA-logo {
        height: 43px;
        margin-top: 3%;
        margin-left: 8%;
    }

    .header-links-nav {
        position: fixed;
        top: 62px;
        width: 100%;
        display: block !important;
        text-align: center;
        background-color: rgb(170, 233, 234);
        left: -150%;
        transition: all .5s linear;

    }
    .dropdown-content-nav , .dropdown-content1-nav{
        position: relative;
      margin: auto;
       
    }
    .header-links-nav ul button {
        margin-left: 35px;
    }
    
}

#check:checked ~ .header-links-nav {
    left: 0;
}

#check:checked~label #btn {
    display: none;
}

#check:checked~label #cancel {
    display: block;
}


/* /////////////////////////////////////////////////////// */



.header-links-nav ul button::after {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    width: 50%;
    height: 0;
    content: "";
    background-color: #000000;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 0 7px 7px 0;
    z-index: 9;
}


.header-links-nav ul button::before {
    position: absolute;
    color: white;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 0;
    content: "";
    background-color: #000000;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-radius: 7px 0 0 7px;
    /* z-index: 9; */

}

.header-links-nav ul button:hover::before {
    height: 100%;
}

.header-links-nav ul button:hover::after {
    height: 100%;
}

.touchbtn-nav span {
    position: relative !important;
    z-index: 99 !important;
}


@media (min-width: 769px) and (max-width: 1024px){
    .REWA-logo{
        margin-left: 15%;
           
        
        }
     
}
@media (min-width: 481px) and (max-width: 768px){
    .header-links-nav ul button {
        margin-left: 35px;
    }
}
.country-container {
    padding: 5px;
  }
  
  .aus-banner {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 400px;
    background-image: url(./images/banimg.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
  filter: brightness(95%);
  }
  .aus-banar-img{
    width: 100%;
    height: 400px;
    filter: brightness(70%);
    position: absolute;
text-align: center;

  }
  .aus-banar-name{
    position: absolute;
    text-align: center;
    color: rgb(246, 243, 243);
    font-family:'Times New Roman', Times, serif;
    text-shadow:
    1px 1px 2px black,
    0 0 0.1em rgb(0, 0, 0),
    0 0 0.2em rgb(0, 0, 0);
    font-weight: 600;
    font-size: 70px;
    margin: 180px;
 

  }
  .country-info{
    text-align: center;
  }
  .aus-tag{
    font-size: 40px;
    font-family: 'Open Sans', sans-serif;
    color: #161616;
    }
    .aus-tag span{
        color: #022d76;
        font-size: 46px;
    }
  .aus-para{

    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    color: #434343;
    font-weight: 300;
    font-style: italic;
    font-size: 1.3125rem;
    line-height: 31px;
   margin: 40px;

}
.aus-info{
    padding: 40px;
    padding-left: 8%;
}
.aus-info-tag{
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    color: #000000c9;
    text-decoration: underline;
   
}
.aus-info li {
    position: relative; 
    padding-left: 25px; 
    list-style-type: none;
    line-height: 30px;

  }
.aus-info li::before {
    content: '✓';
    position: absolute;
    left: 0; 
    color: #3516e6c9;
    font-size: 18px;
  }
.aus-img-info{
     max-width: 85%;
     margin: 60px auto;
     margin-bottom: 64px;
     /* padding-right: .9375rem;
     padding-left: .9375rem; */
     display: flex;
     padding-bottom: 60px;
}

  .aus-left-img-part{
    height: 300px;
    width: 50%;
  }
  .aus-down-img{
    height: 380px;
    /* width: 95%; */
    border-radius: 10px;
    filter:contrast(120%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}
  .aus-right-info-part{

    height: 300px;
    width: 50%;
    margin-top: -20px;
    text-align: center;
  }
  .about-head-aus span {
    color: rgb(0, 0, 0);
    font-size: 42px;
}
.about-head-aus{
    font-size: 40px;
font-family:'Times New Roman', Times, serif;
color: #022d76;
font-weight: 600;

}
.about-para1-aus{
    font-size: medium;
    font-style: italic;
    color: #504f4f;
}
.about-para2-aus{
    line-height: 2;
    color: #5e5d5d;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    padding: 15px;
    text-align: justify;
    font-weight: 400;

}

/* /////////////////////////////////////////// */


/* Large Tablets (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .aus-banner {
    height: 350px;
    background-position-y: 60%;
  }
  .aus-banar-name {
    font-size: 50px;
    margin: 150px;
  }
  .aus-tag {
    font-size: 34px;
  }
  .aus-para {
    margin: 30px;
    font-size: 1.2rem;
  }
  .aus-info {
    padding: 30px;
  }
  .aus-info-tag {
    font-size: 28px;
  }
  .aus-img-info {
    flex-direction: row;
  }
  .aus-left-img-part, .aus-right-info-part {
    width: 50%;
  }
  .aus-down-img {
    height: 320px;
    width: 95%;
  }
  .about-head-aus {
    font-size: 38px;
  }
  .about-para2-aus {
    font-size: 15px;
  }
}

/* Small Tablets (481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .aus-banner {
    height: 300px;
    background-position-y: 50%;
  }
  .aus-banar-name {
    font-size: 40px;
    margin: 130px;
  }
  .aus-tag {
    font-size: 30px;
  }
  .aus-para {
    margin: 25px;
    font-size: 1.1rem;
  }
  .aus-info {
    padding: 25px;
  }
  .aus-info-tag {
    font-size: 26px;
  }
  .aus-img-info {
    flex-direction: column;
  }
  .aus-left-img-part, .aus-right-info-part {
    width: 100%;
  }
  .aus-down-img {
    height: 300px;
    margin-left: 16%;
    margin-top: -6%;
  }
  .about-head-aus {
    font-size: 35px;
  }
  .about-para2-aus {
    font-size: 15px;
  }
}

/* Large Phones (321px - 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .aus-banner {
    height: 250px;
    background-position-y: 40%;
  }
  .aus-banar-name {
    font-size: 32px;
    margin: 110px;
  }
  .aus-tag {
    font-size: 28px;
  }
  .aus-para {
    margin: 20px;
    font-size: 1rem;
  }
  .aus-info {
    padding: 20px;
  }
  .aus-info-tag {
    font-size: 24px;
  }
  .aus-img-info {
    flex-direction: column;
  }
  .aus-left-img-part, .aus-right-info-part {
    width: 100%;
  }
  .aus-down-img {
    height: 210px;
    margin-bottom: 20px;
  }
  .about-head-aus {
    font-size: 29px;
  }
  .about-para2-aus {
    font-size: 13px;
  }
}

/* Small Phones (max-width: 320px) */
@media (max-width: 320px) {
  .aus-banner {
    height: 200px;
    background-position-y: 30%;
  }
  .aus-banar-name {
    font-size: 24px;
    margin: 90px;
  }
  .aus-tag {
    font-size: 22px;
  }
  .aus-para {
    margin: 15px;
    font-size: 0.9rem;
  }
  .aus-info {
    padding: 15px;
  }
  .aus-info-tag {
    font-size: 20px;
  }
  .aus-img-info {
    flex-direction: column;
  }
  .aus-left-img-part, .aus-right-info-part {
    width: 100%;
  }
  .aus-down-img {
    height: 180px;
    margin-bottom: 15px;
  }
  .about-head-aus {
    font-size: 24px;
  }
  .about-para2-aus {
    font-size: 12px;
  }
}

/* styles.css */
.slider-container {
    width: 100%; 
  }
  
  .slide {
    position: relative;
    outline: none; /* To remove the default focus outline on slides */
  }
  
  .slide-background {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 400px;
    /* border-radius: 10px; */
    overflow: hidden;
  }
  
  .slide-content {
    position: absolute;
    color: rgb(0, 0, 0);
  /* left: 30px; */
    padding: 20px;
    max-width: 80%;
    animation: fadeIn 1s ease-in-out;
  }
  .slide-content.slide-2 {
    background-color: rgba(0, 0, 0, 0.377); 
    color: white;
  }
  
  /* Style for the second slide */
  .slide-content.slide-1 {
    left: 35px;
  }
  
  .slide-content h2 {
    font-size: 75px;
    margin: 0 0 10px;
    text-transform: uppercase;
    
  }
  
  .slide-content p {
    font-size: 28px;
    margin: 0 0 10px;
  }
  
  .slide-content button {
    background-color: orange;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .slide-content button:hover {
    background-color: rgb(255, 140, 0);
  }
  .animated-text {
    display: inline-block;
    white-space: nowrap; /* Prevents line breaks, keeps text in one line */
  }
  
  .animated-letter {
    display: inline-block;
    margin-right: 2px; /* Adjust spacing between letters */
  }
  

@keyframes slowZoomEffect {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.01); /* Slight zoom-in */
  }
  100% {
    transform: scale(1);
  }
}

.slide-background {
  animation: slowZoomEffect 20s ease-in-out infinite;
}





/* Default Styles (Desktop View) */
.slider-container {
  width: 100%; 
}

.slide-background {
  height: 400px;
}

.slide-content h2 {
  font-size: 75px;
}

.slide-content p {
  font-size: 28px;

}

.slide-content button {
  font-size: 16px;
}

/* Laptop View (Between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .slide-background {
    height: 350px;
  }

  .slide-content h2 {
    font-size: 60px;
  }

  .slide-content p {
    font-size: 24px;
  }

  .slide-content button {
    font-size: 14px;
  }
}

/* Tablet View (Between 481px and 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .slide-background {
    height: 300px;
  }

  .slide-content {
    max-width: 90%;
    padding: 15px;
  }

  .slide-content h2 {
    font-size: 50px;
  }

  .slide-content p {
    font-size: 20px;
  }

  .slide-content button {
    font-size: 14px;
  }
}

/* Mobile View (Up to 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .slide-background {
    height: 250px;
  }

  .slide-content h2 {
    font-size: 40px;
  }

  .slide-content p {
    font-size: 18px;
  }

  .slide-content button {
    font-size: 12px;
    padding: 8px 16px;
  }
}

/* Very Small Mobile View (Up to 320px) */
@media (max-width: 320px) {
  .slide-background {
    height: 200px;
  }

  .slide-content h2 {
    font-size: 32px;
  }

  .slide-content p {
    font-size: 16px;
  }

  .slide-content button {
    font-size: 10px;
    padding: 6px 12px;
  }
}

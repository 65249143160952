.footer {
  background-color: #011232; 
  color: rgb(172, 170, 170);
  /* padding: 40px; */
  text-align: left; 
  height: 350px;
  padding-top: 30px;

}
.footer-heading-tag{
  color: rgb(255, 255, 255);
}
.REWA-logo-foot{
  height: 55px;
}

.footer-content {
  display: flex; 
  justify-content: space-between; 
  padding-top: 40px;
  padding-left: 40px;
}

.footer-section {
  width: 23%;
}

.footer-section h2 {
  margin-top: 0; 
}

.footer-section ul {
  list-style-type: none; 
  padding: 0;
}

.footer-section ul li {
  margin: 10px 0;
}

.footer-section a {
  color: #61dafb; 
  text-decoration: none; 
}

.footer-section a:hover {
  color: white;
  text-decoration: underline; 
}

.footer-bottom {
 
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  color: black;
  margin-top: 8%;


}
.footer-social-icons {
  display: flex;
}

.foot-icon {
  color: white;
  font-size: 20px; /* Adjust size as needed */
  margin-right: 15px; /* Space between icons */
  transition: color 0.3s;
}

.foot-icon:hover {
  color: #ffcc00; /* Change color on hover */
  transform: scale(1.2);
}




/* //////////////////////////////// */
/* Media Query for screens 768px and below */
@media (min-width:481px) and (max-width: 768px) {
  .footer {
    text-align: center; /* Center align text for smaller screens */
    height: auto; /* Let height adjust based on content */
    padding: 20px; /* Reduce padding */
  }

  .footer-content {
    display: flex;
    flex-direction: column; /* Stack sections vertically */
    align-items: center; /* Center align sections */
    padding-top: 20px;
    padding-left: 0;
  }

  .footer-section {
    width: 100%; /* Make each section full-width */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .footer-section h2 {
    font-size: 18px; /* Adjust heading size */
  }

  .footer-section ul li {
    margin: 8px 0; /* Adjust spacing between list items */
  }

  .footer-bottom {
    flex-direction: column; /* Stack content vertically */
    height: auto; 
    margin-top: 20px;
    padding: 10px;
  }

  .footer-social-icons {
    justify-content: center; /* Center icons */
    margin-top: 10px;
  }

  .foot-icon {
    font-size: 24px; /* Increase icon size for better touch targets */
    margin-right: 10px; 
    margin-bottom: 10px; /* Add spacing for stacked layout */
  }
}


/* Mobile View (max-width: 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .footer {
    height: auto; /* Allow footer to grow based on content */
    padding: 20px; /* Adjust padding */
    text-align: center; /* Center text for smaller screens */
  }

  .footer-content {
    flex-direction: column; /* Stack sections vertically */
    padding: 0; /* Remove padding */
  }

  .footer-section {
    width: 100%; /* Full width for sections */
    margin-bottom: 20px; /* Space between sections */
  }

  .footer-section h2 {
    font-size: 24px; /* Smaller heading font size */
  }

  .footer-section a {
    font-size: 14px; /* Adjust link font size */
  }

  .footer-bottom {
    height: 50px; /* Adjust height for footer bottom */
    font-size: 14px; /* Smaller font size */
  }
  .footer-social-icons {
    justify-content: center; /* Center icons */
    margin-top: 10px;
  }
  .foot-icon {
    font-size: 18px; /* Smaller social icon size */
    margin-right: 10px; /* Reduce space between icons */
    margin-left: 25px;
  }
}

/* Tablet View (min-width: 481px and max-width: 768px) */

/* Laptop View (min-width: 769px and above) */



@media (max-width: 320px) {
  .footer {
    height: auto; /* Allow footer to grow based on content */
    padding: 20px; /* Adjust padding */
    text-align: center; /* Center text for smaller screens */
  }

  .footer-content {
    flex-direction: column; /* Stack sections vertically */
    padding: 0; /* Remove padding */
  }

  .footer-section {
    width: 100%; /* Full width for sections */
    margin-bottom: 20px; /* Space between sections */
  }

  .footer-section h2 {
    font-size: 24px; /* Smaller heading font size */
  }

  .footer-section a {
    font-size: 14px; /* Adjust link font size */
  }

  .footer-bottom {
    height: 50px; /* Adjust height for footer bottom */
    font-size: 14px; /* Smaller font size */
  }

  .foot-icon {
    font-size: 18px; /* Smaller social icon size */
    margin-right: 10px; /* Reduce space between icons */
    margin-left: 25px;
  }
  .footer-social-icons {
    display: flex;
    /* margin-left: 5px; */
    justify-content: center; /* Center icons */

}

}
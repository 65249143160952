
/* Default Styles (Desktop View) */
.welcome-container {
  background-color: rgba(255, 255, 255, 0.252);
}

.welcome-upper-part {
  display: block;
  text-align: center;
  max-width: 51.875rem;
  margin: 60px auto 64px;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.welcome-upper {
  height: 200px;
  width: 100%;
}

.welcome-tag {
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
  color: #022d76;
}

.welcome-tag span {
  color: #000000;
  font-size: 46px;
}

.welcome-para {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #434343;
  font-weight: 300;
  font-style: italic;
  font-size: 1.3125rem;
  line-height: 31px;
}

.welcome-lower {
  max-width: 85%;
  margin: 60px auto 64px;
  display: flex;
}

.welcome-img {
  height: 500px;
  width: 98%;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.lower-left,
.lower-right {
  height: 500px;
  width: 50%;
}

.lower-right {
  text-align: center;
}

.about-head {
  font-size: 40px;
  font-family: 'Times New Roman', Times, serif;
  color: #022d76;
  font-weight: 600;
}

.about-head span {
  color: #000000;
font-size: 45px;
}

.about-para1 {
  font-size: medium;
  font-style: italic;
  color: #504f4f;
}

.about-para2 {
  line-height: 2;
  color: #5e5d5d;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
  padding: 15px;
  text-align: justify;
  font-weight: 400;
}

/* Laptop View (Between 768px and 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .welcome-upper-part {
    margin: 50px auto;
  }

  .welcome-tag {
    font-size: 36px;
  }

  .welcome-tag span {
    font-size: 42px;
  }

  .welcome-para {
    font-size: 1.2rem;
    line-height: 28px;
  }

  .welcome-lower {
    max-width: 90%;
  }

  .welcome-img {
    height: 400px;
  }

  .lower-left,
  .lower-right {
    height: 400px;
  }

  .about-head {
    font-size: 36px;
  }

  .about-para2 {
    font-size: 16px;
  }
}

/* Tablet View (Between 481px and 767px) */
@media (min-width: 481px) and (max-width: 768px) {
  .welcome-upper-part {
    margin: 40px auto;
    padding: 0 0.75rem;
  }

  .welcome-tag {
    font-size: 32px;
  }

  .welcome-tag span {
    font-size: 36px;
  }

  .welcome-para {
    font-size: 1.1rem;
    line-height: 26px;
  }

  .welcome-lower {
    display: block;
    margin: 50px auto;
  }

  .welcome-img {
    height: 350px;
  }

  .lower-left,
  .lower-right {
    width: 100%;
    height: auto;
  }

  .about-head {
    font-size: 32px;
  }

  .about-para2 {
    font-size: 15px;
  }
}

/* Mobile View (Up to 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .welcome-upper-part {
    margin: 30px auto;
    padding: 0 0.5rem;
  }

  .welcome-tag {
    font-size: 28px;
  }

  .welcome-tag span {
    font-size: 32px;
  }

  .welcome-para {
    font-size: 1rem;
    line-height: 24px;
  }

  .welcome-lower {
    display: block;
    margin: 40px auto;
  }

  .welcome-img {
    height: 300px;
    width: 100%;
  }

  .lower-left,
  .lower-right {
    width: 100%;
    height: auto;
  }

  .about-head {
    font-size: 28px;
  }

  .about-para1 {
    font-size: small;
  }

  .about-para2 {
    font-size: 14px;
  }
}

/* Very Small Mobile View (Up to 320px) */
@media (max-width: 320px) {
  .welcome-upper-part {
    margin: 30px auto;
    padding: 0 0.5rem;
  }

  .welcome-tag {
    font-size: 28px;
  }

  .welcome-tag span {
    font-size: 32px;
  }

  .welcome-para {
    font-size: 1rem;
    line-height: 24px;
  }

  .welcome-lower {
    display: block;
    margin: 40px auto;
  }

  .welcome-img {
    height: 300px;
    width: 100%;
  }

  .lower-left,
  .lower-right {
    width: 100%;
    height: auto;
  }

  .about-head {
    font-size: 25px;
  }

  .about-para1 {
    font-size: small;
  }

  .about-para2 {
    font-size: 12px;
  }
}
/* Base Styles (Desktop View) */
.our-impact-main {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(157, 210, 238, 0.5);
  position: relative;
  flex-wrap: wrap;
}

.impact-headding {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 45px;
  margin-top: 50px;
  font-family: emoji;
}

.our-impact-sub {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 20%;
  text-align: center;
  min-width: 150px;
  box-sizing: border-box;
}

.snip1533-sst {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #1f1d1d;
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 16px;
  margin: 10px;
  max-width: 250px;
  min-width: 200px;
  position: relative;
  text-align: center;
  border-radius: 5px;
  height: 180px;
  padding: 20px;
  transition: transform 0.3s ease;
}

.snip1533-sst:hover .count-icons {
  transform: rotateY(180deg);
  transition: 0.8s ease;
}

.counting-name {
  margin-top: -15px;
}

.counting {
  font-size: 35px;
  font-weight: 600;
  margin-top: 7px;
  color: rgb(255, 102, 0);
}

.count-icons {
  height: 85px;
  width: 85px;
  color: rgb(5, 39, 113);


  transition: transform 0.9s;
}

/* Media Queries */

/* Large screens (min-width: 1200px) */
@media (min-width: 1200px) {
  .our-impact-main {
    flex-wrap: nowrap; /* Ensure all items fit in a single row */
  }

  .our-impact-sub {
    width: 20%; /* Consistent width for all items */
  }
}

/* Medium screens (min-width: 768px and max-width: 1199px) */
@media (min-width: 768px) and (max-width: 1199px) {
  .our-impact-main {
    flex-wrap: wrap;
  }
  .counting-name {
    font-size: 20px;
}
.count-icons{
  height: 70px;
  width: 70px;
}
  .our-impact-sub {
    width: 30%; /* Adjust width to fit three items per row */
  }
}

/* Small screens (min-width: 481px and max-width: 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .our-impact-main {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .our-impact-sub {
    width: 45%; /* Two items per row */
  }

  .count-icons {
    height: 60px;
    width: 60px;
  }

  .counting {
    font-size: 25px;
  }
}

/* Extra small mobile view (max-width: 480px) */
@media (max-width: 480px) {
  .our-impact-main {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .impact-headding {
    font-size: 24px;
    margin-top: 10px;
  }

  .our-impact-sub {
    width: 100%;
    margin: 15px 0;
  }

  .snip1533-sst {
    width: 90%;
    margin: 10px auto;
    height: auto;
    padding: 15px;
  }

  .count-icons {
    height: 50px;
    width: 50px;
  }

  .counting {
    font-size: 20px;
  }
}

/* Extra small devices (min-width: 320px and max-width: 480px) */
@media (min-width: 320px) and (max-width: 480px) {
  .our-impact-main {
    padding: 0.5rem;
  }

  .impact-headding {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .our-impact-sub {
    width: 100%;
    margin: -15px 0;
  }

  .snip1533-sst {
    width: 95%;
    padding: 10px;
    height: auto;
  }

  .count-icons {
    height: 40px;
    width: 40px;
  }

  .counting {
    font-size: 18px;
  }

  .counting-name {
    font-size: 0.75rem;
  }
}

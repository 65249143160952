
.contact-info{
    border: 1px solid black;
    border: none;
    height: 500px;
    width: 40%;
    text-align: center;
    position: absolute;
    margin: 3%;
    
}
.info-para1{
    color: rgb(255, 255, 255);
    font-weight: 600;
    text-shadow: 1px white;
    text-shadow: 2px 2px 0px  #000, -2px -2px 0px  #000, 2px -2px 0px  #000, -2px 2px 0px  #000;
    font-size: 40px;
    font-family: Georgia;
}
.info-para2{
    color: rgb(255, 255, 255);
    font-weight: 400;
    text-shadow: 1px rgb(0, 0, 0);
    text-shadow: 2px 2px 0px  #000000, -2px -2px 0px  #000000, 2px -2px 0px  #000000, -2px 2px 0px  #000000;
    font-size:35px;
    font-family:'Franklin Gothic Medium';

}
.info-head{
    color: rgb(255, 0, 0);
    font-size: 48px;
    font-weight: 500;
    text-shadow: 2px 2px 0px  #ffffff, -2px -2px 0px  #ffffff, 2px -2px 0px  #ffffff, -2px 2px 0px  #ffffff;

    
}
.contact-form-div{
    border: 1px solid rgba(0, 0, 0, 0);
    position: relative;
    /* background-image: url("../src/images/login.jpg"); */
    /* filter:contrast(150%); */
    /* background-repeat: no-repeat;
    background-size: cover; */
    width: 100%;
    height: 600px;
    display: flex;



}
.contact-form-img{
    width: 100%;
    height: 600px;
    filter:contrast(105%)
}
.form-headding{
    /* border: 1px solid black; */
    /* width: 50%; */
    align-items: center;
  /* background-color: #000; */
    text-decoration-line: underline;
    font-size: 30px;
    text-align: center;
    /* padding: 20px; */
    color: rgb(0, 0, 0);
    /* margin-left: 25%; */
}
.msg-icon{
    /* color: black; */
    height: 33px;
    width: 33px;
    margin-bottom: -6px;
}
.form-div{
    background-color: rgb(255, 255, 255);
position: absolute;
    width: 100%;
    border: 1px solid rgba(65, 8, 8, 0.493);
    border: none;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5); */
    height:116%;
    /* border-radius: 10px; */

}

.label-name {
    font-size: medium;
    padding: 10px;
    width: 180px;
    font-weight: 500;
}
.label-name input{
    width: 500px;
    height: 32px;
}
.contact-form{
    padding-left: 35px;
    /* margin-top: -20px; */
}
.select-country {
    width: 505px; 
    height: 32px;
  
  }
.text-box{
    height: 70px;
    width: 500px;
}
.submit-botton{
    background-color: rgba(181, 173, 173, 0.486);
    padding: 5px;
    height: 33px;
    width: 90px;
    color: rgb(4, 4, 68);
    font-weight: 600;
    font-size: 17px;
    cursor:pointer;
    margin-left: 37%;
    margin-top:3%;
  
}
.submit-botton:hover{
    color: white;
    background-color: rgb(22, 21, 21);
}

@media (min-width: 768px) and (max-width: 1024px) {
    .label-name input {
        width: 350px;
        height: 30px;
        margin-top: 1px;
    }
    .contact-form {
        padding-left: 17px !important;
      }
      .select-country {
        width: 355px; 
        height: 30px;
      
      }
      .text-box {
        height: 70px;
        width: 350px;
        margin-top: 2px;
    }

}


@media (min-width: 481px) and (max-width: 768px)
    {
    .label-name input {
        width: 500px;
        height: 32px;
    }
    .contact-form {
        padding-left: 120px !important;
      }
      .select-country {
        width: 505px; 
        height: 32px;
      
      }
      .text-box {
        height: 70px;
        width: 500px;
    }
}
@media (min-width: 321px) and (max-width: 480px) {
    .label-name input {
        width: 300px;
        height: 25px;
    }
    .contact-form {
        padding-left: 20px !important;
      }
      .select-country {
        width: 305px; 
        height: 25px;
      
      }
      .text-box {
        height: 68px;
        width: 300px;
    }

}
@media (max-width: 320px) {
    .label-name input {
        width: 250px;
        height: 20px;
    }
    .contact-form {
        padding-left: 20px !important;
      }
      .select-country {
        width: 255px; 
        height: 20px;
      
      }
      .text-box {
        height: 65px;
        width: 250px;
    }

}
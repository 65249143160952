.marquee-container {
    overflow: hidden;
    white-space: nowrap;
}

.university-heading {
    text-decoration:underline;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 45px;
    margin: 40px;
        margin-top: 60px;
        font-family: emoji;
    text-transform:uppercase;
}
.university-description {
    text-align: center;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    color: #434343;
    font-weight: 300;
    font-style: italic;
    font-size: 1.3125rem;
    line-height: 31px;
    margin-bottom: 80px;
 
}


.marquee-image {
    width: 250px; /* Increased image size */
    height: auto; /* Maintain aspect ratio */
    margin: 0 20px; /* Space around each image */
    display:inline-block; /* Keep images in line */
    padding: 30px;
    
}



/* ////////////////////////////////////////// */

/* Default Styles (Desktop View) */
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
  }
  
  .university-heading {
    text-decoration: underline;
    color: rgb(0, 0, 0);
    text-align: center;
    font-size: 45px;
    margin: 40px;
    margin-top: 60px;
    font-family: emoji;
    text-transform: uppercase;
  }
  
  .university-description {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    color: #434343;
    font-weight: 300;
    font-style: italic;
    font-size: 1.3125rem;
    line-height: 31px;
    margin-bottom: 80px;
  }
  
  .marquee-image {
    width: 250px;
    height: auto;
    margin: 0 20px;
    display: inline-block;
    padding: 30px;
  }
  
  /* Laptop View (Between 768px and 1024px) */
  @media (min-width: 769px) and (max-width: 1024px) {
    .university-heading {
      font-size: 38px;
      margin: 30px;
    }
  
    .university-description {
      font-size: 15px;
      line-height: 28px;
      margin-bottom: 60px;
    }
  
    .marquee-image {
      width: 200px;
      padding: 20px;
    }
  }
  
  /* Tablet View (Between 481px and 767px) */
  @media (min-width: 481px) and (max-width: 768px) {
    .university-heading {
      font-size: 32px;
      margin: 25px;
      margin-top: 50px;
    }
  
    .university-description {
      font-size: 11px;
      line-height: 26px;
      margin-bottom: 50px;
    }
  
    .marquee-image {
      width: 180px;
      padding: 15px;
      margin: 0 15px;
    }
  }
  
  /* Mobile View (Up to 480px) */
  @media (min-width: 321px) and  (max-width: 480px) {
    .university-heading {
      font-size: 28px;
      margin: 20px;
      margin-top: 40px;
    }
  
    .university-description {
      font-size: 6px;
      line-height: 24px;
      margin-bottom: 40px;
    }
  
    .marquee-image {
      width: 150px;
      padding: 10px;
      margin: 0 10px;
    }
  }
  
  /* Very Small Mobile View (Up to 320px) */
  @media (max-width: 320px) {
    .university-heading {
      font-size: 24px;
      margin: 15px;
      margin-top: 30px;
    }
  
    .university-description {
      font-size: 5px;
      line-height: 22px;
      margin-bottom: 30px;
    }
  
    .marquee-image {
      width: 120px;
      padding: 8px;
      margin: 0 8px;
    }
  }
  
.study-abroad-banner {
  text-align: center;
  background-color: #f0f4f8;
  padding: 40px;


}

.study-abroad-banner h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #24282c;
}

.study-abroad-banner p {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: #526579;
}

.chat-button {
  border: 1px black solid;
  font-weight: 500;
  background-color: #000000;
  color: #fff;
  padding: 15px 30px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 20px rgba(50, 49, 49, 0.5);
text-transform: uppercase;
  transition: background-color 0.3s;
}

.chat-button:hover {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  
}



.container {
    text-align: center;
    padding: 30px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 800px; 
    margin: auto;
    font-size: 22px;
    background-color: rgba(157, 210, 238, 0.5);
    margin-bottom: 70px;
    margin-top: 50px;
  }
  
  .button-container {
    margin-top: 20px;
  }
  
  .button {
    display: inline-block;
    margin: 25px; 
    padding: 12px 24px; 
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    background-color: #ffffff;
    text-decoration: none;
    /* border-radius: 5px; */
    border: 1px solid black;
    transition: background-color 0.3s;
    text-transform: uppercase;
  }
  
  .button:hover {
    background-color: #02162b; 
    color: rgb(255, 255, 255);
  }
  /* /////////////////////////////////////// */

  /* Large Tablets (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .study-abroad-banner {
    padding: 30px;
  }
  .study-abroad-banner h1 {
    font-size: 2.2em;
  }
  .study-abroad-banner p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }
  .chat-button {
    padding: 12px 25px;
    font-size: 0.9em;
  }
  .container {
    padding: 25px;
    max-width: 700px;
  }
  .button {
    padding: 10px 20px;
    font-size: 14px;
    margin: 15px;
  }
}

/* Small Tablets (481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .study-abroad-banner {
    padding: 25px;
  }
  .study-abroad-banner h1 {
    font-size: 2em;
  }
  .study-abroad-banner p {
    font-size: 1em;
    margin-bottom: 15px;
  }
  .chat-button {
    padding: 10px 20px;
    font-size: 0.9em;
  }
  .container {
    padding: 20px;
    max-width: 90%;
  }
  .con-heading{
    font-size: 35px;
  }
  .button {
    padding: 8px 18px;
    font-size: 14px;
    margin: 15px;
  }
}

/* Large Phones (321px - 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .study-abroad-banner {
    padding: 20px;
    margin-top: 30px;
  }
  .study-abroad-banner h1 {
    font-size: 1.8em;
  }
  .study-abroad-banner p {
    font-size: 0.95em;
    margin-bottom: 15px;
  }
  .chat-button {
    padding: 10px 18px;
    font-size: 0.85em;
  }
  .container {
    padding: 15px;
    max-width: 90%;
  }
  .con-heading{
    font-size: 28px;
  }
  .button {
    padding: 8px 16px;
    font-size: 13px;
    margin: 10px;
  }
}

/* Small Phones (max-width: 320px) */
@media (max-width: 320px) {
  .study-abroad-banner {
    padding: 15px;
    margin-top: 50px;
  }
  .study-abroad-banner h1 {
    font-size: 1.5em;
  }
  .study-abroad-banner p {
    font-size: 0.9em;
    margin-bottom: 10px;
  }
  .chat-button {
    padding: 8px 15px;
    font-size: 0.8em;
  }
  .container {
    padding: 10px;
    max-width: 95%;
  }
  .con-heading{
    font-size: 23px;
  }
  .button {
   height: 20px;
     }
}

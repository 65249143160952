.country-container {
    padding: 5px;
  }
  
  .usa-banner {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center; 
    width: 100%;
    height: 400px;
    background-image: url(./images/banimg.jpg);
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
  filter: brightness(95%);
  }

  .Usa-banar-name{
    position: absolute;
    color: rgb(246, 243, 243);
    font-family:'Times New Roman', Times, serif;
    text-shadow:
    1px 1px 2px black,
    0 0 0.1em rgb(0, 0, 0),
    0 0 0.2em rgb(0, 0, 0);
    font-weight: 600;
    font-size: 70px;
  
  }
  .country-info{
    text-align: center;
  }
  .usa-tag{
    font-size: 40px;
    font-family: 'Open Sans', sans-serif;
    color: #111112;
    }
    .usa-tag span{
        color: #022d76;
        font-size: 46px;
    }
  .usa-para{

    font-family: 'Open Sans', sans-serif;
    margin-bottom: 0;
    color: #434343;
    font-weight: 300;
    font-style: italic;
    font-size: 1.3125rem;
    line-height: 31px;
   margin: 40px;

}
.usa-info{
    padding: 40px;
    padding-left: 8%;
}
.usa-info-tag{
    font-size: 32px;
    font-family: 'Open Sans', sans-serif;
    color: #000000c9;
    text-decoration: underline;
   
}
.usa-info li {
    position: relative; 
    padding-left: 25px; 
    list-style-type: none;
    line-height: 30px;

  }
.usa-info li::before {
    content: '✓';
    position: absolute;
    left: 0; 
    color: #3516e6c9;
    font-size: 18px;
  }
.usa-img-info{
     max-width: 85%;
     margin: 60px auto;
     margin-bottom: 64px;
     /* padding-right: .9375rem;
     padding-left: .9375rem; */
     display: flex;
     padding-bottom: 60px;
}

  .usa-left-img-part{
    height: 300px;
    width: 50%;
  }
  .usa-down-img{
    height: 380px;
    /* width: 95%; */
    border-radius: 10px;
    filter:contrast(120%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}
  .usa-right-info-part{

    height: 300px;
    width: 50%;
    margin-top: -20px;
    text-align: center;
  }
  .about-head-usa span {
    color: #000000;
    font-size: 42px;
}
.about-head-usa{
    font-size: 40px;
font-family:'Times New Roman', Times, serif;
color: #022d76;
font-weight: 600;

}
.about-para1-usa{
    font-size: medium;
    font-style: italic;
    color: #504f4f;
}
.about-para2-usa{
    line-height: 2;
    color: #5e5d5d;
    font-size: 17px;
    font-family: 'Open Sans', sans-serif;
    padding: 15px;
    text-align: justify;
    font-weight: 400;

}

/* ///////////////////////////////// */
/* Large Tablets (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .usa-banner {
    height: 350px;
    background-position-y: 60%;
  }
  .Usa-banar-name {
    font-size: 50px;
  }
  .usa-tag {
    font-size: 36px;
  }
  .usa-para {
    margin: 30px;
    font-size: 1.2rem;
  }
  .usa-info {
    padding: 30px;
  }
  .usa-info-tag {
    font-size: 28px;
  }
  .usa-img-info {
    flex-direction: row;
  }
  .usa-left-img-part,
  .usa-right-info-part {
    width: 50%;
  }
  .usa-down-img {
    height: 320px;
    width: 95%;
  }
  .about-head-usa {
    font-size: 38px;
  }
  .about-para2-usa {
    font-size: 15px;
  }
}

/* Small Tablets (481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .usa-banner {
    height: 300px;
    background-position-y: 50%;
  }
  .Usa-banar-name {
    font-size: 42px;
  }
  .usa-tag {
    font-size: 32px;
  }
  .usa-para {
    margin: 25px;
    font-size: 1.1rem;
  }
  .usa-info {
    padding: 25px;
  }
  .usa-info-tag {
    font-size: 26px;
  }
  .usa-img-info {
    flex-direction: column;
  }
  .usa-left-img-part,
  .usa-right-info-part {
    width: 100%;
  }
  .usa-down-img {
    height: 300px;
    margin-left: 16%;
    margin-top: -6%;
  }
  .about-head-usa {
    font-size: 34px;
  }
  .about-para2-usa {
    font-size: 15px;
  }
}

/* Large Phones (321px - 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .usa-banner {
    height: 250px;
    background-position-y: 40%;
  }
  .Usa-banar-name {
    font-size: 32px;
  }
  .usa-tag {
    font-size: 28px;
  }
  .usa-para {
    margin: 20px;
    font-size: 1rem;
  }
  .usa-info {
    padding: 20px;
  }
  .usa-info-tag {
    font-size: 24px;
  }
  .usa-img-info {
    flex-direction: column;
  }
  .usa-left-img-part,
  .usa-right-info-part {
    width: 100%;
  }
  .usa-down-img {
    height: 210px;
    margin-bottom: 15px;
  }
  .about-head-usa {
    font-size: 28px;
  }
  .about-para2-usa {
    font-size: 13px;
  }
}

/* Small Phones (max-width: 320px) */
@media (max-width: 320px) {
  .usa-banner {
    height: 200px;
    background-position-y: 30%;
  }
  .Usa-banar-name {
    font-size: 24px;
  }
  .usa-tag {
    font-size: 22px;
  }
  .usa-para {
    margin: 15px;
    font-size: 0.9rem;
  }
  .usa-info {
    padding: 15px;
  }
  .usa-info-tag {
    font-size: 20px;
  }
  .usa-img-info {
    flex-direction: column;
  }
  .usa-left-img-part,
  .usa-right-info-part {
    width: 100%;
  }
  .usa-down-img {
    height: 180px;
    margin-bottom: 10px;
  }
  .about-head-usa {
    font-size: 24px;
  }
  .about-para2-usa {
    font-size: 12px;
  }
}


.oneline-footer-baar {
    background-color: #000000; /* Change to your theme color */
    color: white;
    padding: 10px 0;
    text-align: center;
  }
  
  .foot-container{
    height: 40px;
    max-width: 1200px; /* Adjust as needed */
    margin: auto;
    padding: 0 15px; /* Side padding */
    display: flex;
    justify-content: space-between; /* Space between social icons and contact button */
    align-items: center; /* Center align items vertically */
  }
  
  .footerbar-social-icons {
    display: flex;
  }
  
  .footbar-icon {
    color: white;
    font-size: 18px; /* Adjust size as needed */
    margin-right: 15px; /* Space between icons */
    transition: color 0.3s;
  }
  
  .footbar-icon:hover {
    color: #ffcc00; /* Change color on hover */
    transform: scale(1.2);
  }
  
  .footer-contact-info {
    margin: 0; /* Remove default margin */
    font-size:15px;
  }
  
  .phone,
  .email {
    margin: 0 10px; /* Space between phone and email */
    color: white; /* Ensuring link color is white */
    text-decoration: none; /* Remove underline */
  }
  
  .phone:hover,
  .email:hover {
    text-decoration: underline; /* Underline on hover */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .footer-contact-button .btn {
    background-color: #fff; /* Button background */
    color: #000000; /* Button text color */
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    text-decoration: none; /* Remove underline */
  }
  
  .footer-contact-button .btn:hover {
    background-color: #d2d0d0;
 
     /* Button hover effect */
  }
  


  /* ////////////////////////////// */


  /* Laptop View (min-width: 769px) */
@media (min-width: 769px) and (max-width: 1024px){
  .foot-container {
    max-width: 1200px; /* Keep original max width */
    height: 40px; /* Original height */
    padding: 0 15px; /* Keep original side padding */
  }

  .footbar-icon {
    font-size: 18px; /* Original icon size */
    margin-right: 15px; /* Original space between icons */
  }

  .footer-contact-info {
    font-size: 15px; /* Original font size */
  }

  .footer-contact-button .btn {
    background-color: #fff; /* Original button background */
    color: #000000; /* Original button text color */
    padding: 8px 16px; /* Original padding */
  }
}

@media (min-width: 319px) and (max-width: 768px) {
 .oneline-footer-baar{
  display: none;
 }
}


.our-banar-img {
  width: 100%;
  height: 400px;
  filter: brightness(70%);
  position: absolute;
  text-align: center;
}

.our-banner{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  background-image: url(./images/banimg.jpg);
  background-size: cover;
  background-attachment: fixed;
  /* background-repeat: no-repeat; */
  filter: brightness(95%);
  
}

.our-banar-name {
  position: absolute;
  color: rgb(246, 243, 243);
  font-family: 'Times New Roman', Times, serif;
  text-shadow:
    1px 1px 2px black,
    0 0 0.1em rgb(0, 0, 0),
    0 0 0.2em rgb(0, 0, 0);
  font-weight: 600;
  font-size: 70px;

}

.our-headding {

  color: rgb(1, 10, 116);
  /* text-shadow:
    1px 1px 1px black,
    0 0 0.0em rgb(0, 0, 0); */
  /* text-align: center; */
  font-size: 40px;
  font-family: emoji;


}

.our-para {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #4a4a4a;
  font-weight: 300;
  font-style: italic;
  font-size: 18px;
  line-height: 31px;
}

.how-we-are-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.our-info-left {
  flex: 1;
  padding: 20px;
  line-height: 2;
  color: #4a4a4a;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-weight: 400;
}

.our-image-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-image {
  width: 80%;
  height: auto;
}

.our-info {
  padding: 30px;
  padding-left: 3%;

}

.our-info-tag {
  color: rgb(1, 10, 116);
  /* text-shadow:
    1px 1px 1px black,
    0 0 0.0em rgb(0, 0, 0); */
  /* text-align: center; */
  font-size: 40px;
  font-family: emoji;
  text-transform: uppercase;


}

.our-info li {
  position: relative;
  padding-left: 25px;
  list-style-type: none;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #4a4a4a;
  font-weight: 300;
  font-style: italic;
  font-size: 18px;


}

.our-info li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #08016ac9;
  font-size: 18px;
  font-weight: 600;
}

/* ///////////////////////////////////////////// */
/* General Styles */
.our-banar-img {
  width: 100%;
  height: 400px;
  filter: brightness(70%);
  position: absolute;
  text-align: center;
}
cd
.our-banar-name {
  position: absolute;
  color: #f6f3f3;
  font-family: 'Times New Roman', Times, serif;
  text-shadow: 1px 1px 2px black, 0 0 0.1em black, 0 0 0.2em black;
  font-weight: 600;
  font-size: 70px;
}

.our-headding {
  color: #010a74;
  font-size: 40px;
  font-family: 'Arial', sans-serif;
}

.our-para {
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #4a4a4a;
  font-weight: 300;
  font-style: italic;
  font-size: 18px;
  line-height: 31px;
}

/* Flex Container for How We Are Section */
.how-we-are-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  gap: 20px;
}

.our-info-left {
  flex: 1;
  padding: 20px;
  line-height: 2;
  color: #4a4a4a;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-weight: 400;
}

.our-image-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-image {
  width: 80%;
  height: auto;
}

.our-info {
  padding: 30px;
  padding-left: 3%;
}

.our-info-tag {
  color: #010a74;
  font-size: 40px;
  font-family: 'Arial', sans-serif;
  text-transform: uppercase;
}

.our-info li {
  position: relative;
  padding-left: 25px;
  list-style-type: none;
  line-height: 30px;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #4a4a4a;
  font-weight: 300;
  font-style: italic;
  font-size: 18px;
}

.our-info li::before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #08016a;
  font-size: 18px;
  font-weight: 600;
}

/* Responsive Media Queries */

/* Large Tablets (769px - 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .our-banner {
    height: 350px;
    background-position-y: 200%;
  }

  .our-banar-name {
    font-size: 50px;
  }

  .how-we-are-container {
    flex-direction: column;
    align-items: center;
  }

  .team-image {
    width: 90%;
  }
}

/* Small Tablets (481px - 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .our-banner {
    height: 320px;
    background-position-y: 180%;
  }

  .our-banar-name {
    font-size: 45px;
  }

  .how-we-are-container {
    flex-direction: column;
    align-items: center;
  }

  .our-info-left {
    padding: 15px;
    font-size: 16px;
  }

  .team-image {
    width: 85%;
  }
}

/* Large Phones (321px - 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .our-banner {
    height: 280px;
    background-position-y: 170%;
  }

  .our-banar-name {
    font-size: 35px;
  }

  .how-we-are-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .our-info-left {
    padding: 10px;
    font-size: 15px;
  }

  .our-info-tag {
    font-size: 30px;
  }

  .our-headding {

    font-size: 30px;

  }

  .team-image {
    width: 85%;
  }
}

/* Small Phones (max-width: 320px) */
@media (max-width: 320px) {
  .our-banner {
    height: 250px;
    background-position-y: 160%;
  }

  .our-banar-name {
    font-size: 30px;
  }

  .how-we-are-container {
    flex-direction: column;
    align-items: center;
  }

  .our-info-left {
    padding: 8px;
    font-size: 14px;
  }

  .our-info-tag {
    font-size: 25px;
  }

  .our-headding {

    font-size: 25px;

  }

  .team-image {
    width: 100%;
  }
}
.profile-evaluation {
  text-align: center;
  margin: 100px;
}

h2 {
  margin-bottom: 30px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.services-headding {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 45px;
  margin-top: 50px;
  font-family: emoji;
}

.services-para {
  /* align-items: center; */
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #434343;
  font-weight: 300;
  font-style: italic;
  font-size: 1.3125rem;
  line-height: 31px;


}

.service-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 10px;

  justify-content: flex-start;
}

.service-image {
  margin-right: 10px;
}


.service-title {
  flex: 1;
  text-align: center;
}

.service-image {
  margin-right: 9px;
  border-radius: 5px;
  background: whitesmoke;
  padding: 2px;
  align-items: self-start;
}

.service-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: #2978cc;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: background-color 0.3s ease;
  position: relative;
}

.service-button:hover {
  background-color: #034081;

}

.service-button:hover .service-image {
  transform: scale(1.2);

}

/* /////////////////////////////////// */


/* Default Styles (Desktop View) */
.profile-evaluation {
  text-align: center;
  margin: 100px;
}

h2 {
  margin-bottom: 30px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.services-headding {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 45px;
  margin-top: 50px;
  font-family: emoji;
}

.services-para {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #434343;
  font-weight: 300;
  font-style: italic;
  font-size: 1.3125rem;
  line-height: 31px;
}

.service-button {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 10px;
  justify-content: flex-start;
}

.service-image {
  margin-right: 10px;
}

.service-title {
  flex: 1;
  text-align: center;
}

.service-image {
  margin-right: 9px;
  border-radius: 5px;
  background: whitesmoke;
  padding: 2px;
  align-items: self-start;
}

.service-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: #2978cc;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: background-color 0.3s ease;
  position: relative;
}

.service-button:hover {
  background-color: #034081;
}

.service-button:hover .service-image {
  transform: scale(1.2);
}

/* Laptop View (Between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .profile-evaluation {
    margin: 80px; /* Reduced margin for smaller screens */
  }

  h2 {
    font-size: 32px; /* Adjusted font size */
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }

  .services-headding {
    font-size: 40px; /* Slightly smaller heading */
  }

  .services-para {
    font-size: 1.2rem; /* Adjusted paragraph size */
  }

  .service-button {
    padding: 8px 16px; /* Adjusted padding */
  }
}

/* Tablet View (Between 481px and 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .profile-evaluation {
    margin: 60px; /* Adjusted margin */
  }

  h2 {
    font-size: 28px; /* Smaller heading */
  }

  .services-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 15px; /* Reduced gap */
  }

  .services-headding {
    font-size: 35px; /* Smaller heading */
  }

  .services-para {
    font-size: 1.1rem; /* Adjusted paragraph size */
  }

  .service-button {
    padding: 8px 14px; /* Adjusted padding */
  }
}

/* Mobile View (Up to 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .profile-evaluation {
    margin: 40px; /* Adjusted margin for mobile */
  }

  h2 {
    font-size: 24px; /* Smaller heading */
  }

  .services-grid {
    grid-template-columns: 1fr; /* Single column */
    gap: 10px; /* Reduced gap */
  }

  .services-headding {
    font-size: 30px; /* Smaller heading */
  }

  .services-para {
    font-size: 1rem; /* Smaller paragraph size */
  }

  .service-button {
    padding: 6px 12px; /* Adjusted padding */
  }

  .service-image {
    margin-right: 5px; /* Adjusted margin */
  }
}

/* Very Small Mobile View (Up to 320px) */
@media (max-width: 320px) {
  .services-grid {
    grid-template-columns: 1fr; /* Single column */
    gap: 10px; /* Reduced gap */
  }

  .profile-evaluation {
    margin: 20px; /* Further reduced margin */
  }

  h2 {
    font-size: 20px; /* Smaller heading */
  }

  .services-headding {
    font-size: 24px; /* Further reduced heading */
  }

  .services-para {
    font-size: 0.9rem; /* Further reduced paragraph size */
  }

  .service-button {
    padding: 5px 10px; /* Further adjusted padding */
  }

  .service-image {
    height: 50px; /* Adjusted image size */
    width: 50px; /* Adjusted image size */
  }
}

.accordion {
    max-width: 900px;
    margin: 20px auto;
    border-radius: 5px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }
  
  .accordion-item {
    /* border: 1px solid #f8f5f5; */
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  }
  .faqs-headding{
    text-decoration:underline;
color: rgb(0, 0, 0);
text-align: center;
font-size: 45px;
    margin-top: 50px;
    font-family: emoji;
  }
  .accordion-header {
    display: flex;
    font-size: 20px;
    padding: 15px;
    cursor: pointer;
    background-color: #ffffff;
    transition: background-color 0.3s;
    font-family:'Times New Roman', Times, serif;
    justify-content: space-between;
  }
  
  .accordion-header:hover {
    background-color: #e0dddd;
  }
  
  .accordion-content {
    /* padding: 15px; */
    color: black;
    font-size: 17px;
    background-color: #ffffff7d;
    border-top: 1px solid #ccc;
    font-family:Georgia, 'Times New Roman', Times, serif;
    line-height: 30px;

    /* transition:ease 5s; */
    
  }
   .accordion-item.active .accordion-header {
    background-color: #cce5ff; /* Color for active (clicked) item */
    color: #0056b3; /* Color for active text */
  }
  
  .accordion-toggle {
    font-size: 28px; /* Adjust size as needed */
    margin-left: 10px; /* Space between title and toggle */

  }
  
  .accordion-header:hover .accordion-toggle{
    transform: scale(1.2); /* Optional scale effect on hover */
  }
  /* //////////////////////////////////////////// */



  /* Default Styles (Desktop View) */
.accordion {
  max-width: 900px;
  margin: 20px auto;
  border-radius: 5px;
}

.accordion-item {
  border-radius: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.faqs-headding {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  text-align: center;
  font-size: 45px;
  margin-top: 50px;
  font-family: emoji;
}

.accordion-header {
  display: flex;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
  background-color: #ffffff;
  transition: background-color 0.3s;
  font-family: 'Times New Roman', Times, serif;
  justify-content: space-between;
}

.accordion-content {
  color: black;
  font-size: 17px;
  background-color: #ffffff7d;
  border-top: 1px solid #ccc;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.accordion-toggle {
  font-size: 28px;
  margin-left: 10px;
}

/* Laptop View (Between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .accordion {
    max-width: 700px;
    margin: 15px auto;
  }

  .faqs-headding {
    font-size: 38px;
    margin-top: 40px;
  }

  .accordion-header {
    font-size: 18px;
    padding: 12px;
  }

  .accordion-content {
    font-size: 16px;
  }

  .accordion-toggle {
    font-size: 26px;
  }
}

/* Tablet View (Between 481px and 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .accordion {
    max-width: 90%;
    margin: 10px auto;
  }

  .faqs-headding {
    font-size: 32px;
    margin-top: 30px;
  }

  .accordion-header {
    font-size: 16px;
    padding: 10px;
  }

  .accordion-content {
    font-size: 15px;
  }

  .accordion-toggle {
    font-size: 24px;
  }
}

/* Mobile View (Up to 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .accordion {
    max-width: 95%;
    margin: 5px auto;
  }

  .faqs-headding {
    font-size: 28px;
    margin-top: 25px;
  }

  .accordion-header {
    font-size: 15px;
    padding: 8px;
  }

  .accordion-content {
    font-size: 14px;
  }

  .accordion-toggle {
    font-size: 22px;
  }
}

/* Very Small Mobile View (Up to 320px) */
@media (max-width: 320px) {
  .accordion {
    max-width: 100%;
    margin: 5px auto;
  }

  .faqs-headding {
    font-size: 24px;
    margin-top: 20px;
  }

  .accordion-header {
    font-size: 14px;
    padding: 6px;
  }

  .accordion-content {
    font-size: 13px;
  }

  .accordion-toggle {
    font-size: 20px;
  }
}



.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.9s;
  transform-style: preserve-3d;
  /* Preserve 3D effect */
}

.card:hover .card-inner {
  transform: rotateY(180deg);
  /* Rotate on hover */
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  /* Hide the back when not facing */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  /* Font size */
  color: white;
  /* Text color */
  border-radius: 10px;
  /* Rounded corners */
  overflow: hidden;
  /* Clip content */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.5);

}
.card-back-button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 13px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 110px;
  margin: 17px auto;
  margin-left:30%;
  font-weight: bolder;
}

.card-back-button:hover {
  background-color: #010d1a; /* Darker shade on hover */
}

.card-back-button:focus {
  outline: none; /* Remove outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Outline for focus */
}


.card-image {
  width: 100%;
  /* Full width of the card */
  height: 300px;
  /* Maintain aspect ratio */
  position: absolute;
  /* Position the image */
  top: 0;
  /* Align to the top */
  left: 0;
  /* Align to the left */
  z-index: 1;
  /* Place image below text */

}

/* ////////////////////////////// */


.card-front {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* Stack content vertically */
  /* background-color: #007bff; Blue */
}

.card-back {
  background-color: #737371;
  /* Green */
  transform: rotateY(180deg);
  /* Initially hidden */
  text-align: center;
  /* Center align text */
}


.card-title {
  position: absolute;
  /* Position the title over the image */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust for centering */
  color: white;
  /* Text color */
  font-size: 1.5rem;
  /* Font size */
  z-index: 2;
  /* Place title above image */
}






/* ////////////////////////////////////////////// */

/* Default Styles (Desktop View) */
.card-container {
  display: grid;
  grid-template-columns: repeat(3, 0.3fr);
  margin: 40px;
  margin-left: 12%;
}

.card-heading {
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  font-family: 'Open Sans', sans-serif;
  color: #161616;
}

.card-heading span {
  color: #022d76;
  font-size: 46px;
}

.card-heading-para {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 0;
  color: #434343;
  font-weight: 400;
  font-style: italic;
  font-size: 1.5125rem;
  line-height: 31px;
}

.card {
  margin: 40px auto;
  width: 70%;
  height: 300px;
}

.card-back-button {
  font-size: 16px;
  width: 110px;
  margin: 17px auto;
  margin-left: 30%;
}

/* Laptop View (Between 768px and 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {
  .card-container {
    grid-template-columns: repeat(2, 0.5fr);
    margin: 30px;
  
  }

  .card-heading {
    font-size: 36px;
  }

  .card-heading span {
    font-size: 42px;
  }

  .card-heading-para {
    font-size: 1.3rem;
    line-height: 28px;
  }

  .card {
    width: 75%;
    height: 280px;
  }

  .card-back-button {
    font-size: 14px;
    width: 100px;
  }
}

/* Tablet View (Between 481px and 767px) */
@media (min-width: 481px) and (max-width: 767px) {
  .card-container {
    grid-template-columns: 1fr;
    margin: 20px;
    margin-left: 5%;
  }

  .card-heading {
    font-size: 32px;
  }

  .card-heading span {
    font-size: 36px;
  }

  .card-heading-para {
    font-size: 1.2rem;
    line-height: 26px;
  }

  .card {
    width: 85%;
    height: 250px;
  }

  .card-back-button {
    font-size: 14px;
    width: 90px;
  }
}

/* Mobile View (Up to 480px) */
@media (max-width: 480px) and (min-width: 321px) {
  .card-container {
    grid-template-columns: 1fr;
    margin: 15px;
    margin-left: 2%;
  }

  .card-heading {
    font-size: 28px;
  }

  .card-heading span {
    font-size: 32px;
  }

  .card-heading-para {
    font-size: 1rem;
    line-height: 24px;
  }

  .card {
    width: 90%;
    height: 220px;
  }

  .card-back-button {
    font-size: 12px;
    width: 80px;
    margin-left: 40%;
  }
 
}

/* Very Small Mobile View (Up to 320px) */
@media (max-width: 320px) {
  .card-container {
    grid-template-columns: 1fr;
    margin: 10px;
    margin-left: 1%;
  }

  .card-heading {
    font-size: 24px;
  }

  .card-heading span {
    font-size: 28px;
  }

  .card-heading-para {
    font-size: 0.9rem;
    line-height: 22px;
  }

  .card {
    width: 95%;
    height: 200px;
  }

  .card-back-button {
    font-size: 10px;
    width: 70px;
  }
}
